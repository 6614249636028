
.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    background-color: #0C4739;
    border-radius: 0;
    z-index: 5;
}

.footer-text {
    font-size: 14px;
    color: white;
    padding-right: 4px;
}

.bold {
    font-weight: bolder;
}

.footer-link {
    font-size: 14px;
    color: #61dafb;
}

.footer-link:hover {
    color: #61dafb;
    text-decoration: underline;
    cursor: pointer;
}