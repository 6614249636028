
.promo-close-button-container {
    width: 100%;
    display: flex;
}

.promo-close-button {
    position: absolute !important;
    top: 20px;
    right: 20px;
}

.promo-container {
    background-color: #0C4739;
}

.promo-content {
    background-color: #0C4739;
    margin: 12px;
}

.promo-text-content {
    color: white;
    text-align: center;
    font-size: 1.2rem;
    margin: 6px 0;
}

.promo-small-text-content {
    color: grey;
    text-align: center;
    font-size: 0.7rem;
}

.promo-button-container {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.promo-button {
    min-width: fit-content;
    margin: 12px;
}

.promo-close-action-button {
    background-color: #0C4739 !important;
    color: white !important;
}